<template>
  <div>
    <div class="websiteWrapper">
      <nav-bar />
      <b-row class="p-0 justify-content-center hold-join-merchant">
        <b-col lg="6" cols="12">
          <div class="py-5">
            <router-view />
          </div>
        </b-col>
        <b-col
          lg="6"
          cols="12"
          class="m-0 p-0"
          :style="{
            'background-size': 'cover',
            'background-image': 'url(' + coverImage + ')',
          }"
        >
          <div class="gradient-green">
            <img
              alt="alattarh"
              :src="require('@/assets/images/ALATTARH-LOGO.png')"
              width="400"
              class="logo-join"
            />
          </div>
        </b-col>
      </b-row>
      <WebsiteFooter />
    </div>
  </div>
</template>
<script>
import NavBar from "@/components/core/nav/NavBar.vue";
import WebsiteFooter from "@/components/core/footer/WebsiteFooter.vue";
export default {
  data() {
    return {
      coverImage: require("@/assets/images/ibbil/homePage/grinder-wooden.jpg"),
    };
  },
  components: {
    NavBar,
    WebsiteFooter,
  },
};
</script>
<style lang="scss" scoped>
@import url("../assets/css/custom.css");
@import url("../assets/css/PriceSlider.css");
.unread {
  left: -16px;
  top: 5px;
  font-size: 9px;
}
.mark {
  background-color: #e3e5fc !important;
}
.allNotifications {
  overflow: auto;
  height: 30rem;
}

.gradient-green {
  background: transparent linear-gradient(9deg, #7a3820 0%, #35a36e6c 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.logo-join {
  @media (max-width: 992px) {
    width: 300px;
  }
}
.hold-join-merchant {
  width: 91%;
  margin: 0 auto 0 0 !important;
  @media (max-width: 1300px) {
    width: 100%;
  }
  &:lang(en) {
    margin: 0 0 0 auto !important;
  }
}
</style>
